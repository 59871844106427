import {generateId} from '../functions'

export const setsDefault = [
	{
		name: 'Set 1',
		warmUp: 0,
		rest: 0,
		roundLength: 30000,
		roundCount: 1,
		id: generateId(),
	}
]

export const timerDefault = {
	id: 1,
	name: 'Timer 1',
	loop: false,
	sets: setsDefault,
	volume: 0.85,
}