import { h, Component } from 'preact'
import style from './style'
import {FloatingButton} from '../../components/buttons'
import * as Sentry from "@sentry/react";
import {clearLocalTimers} from '../../helpers/functions'

export default class extends Component {
	constructor() {
		super()
		this.state = { errored: false }
	}

	componentDidCatch(error) {
		this.setState({ errored: true })
		document.body.classList.add('darkMode')
		console.log(error)
	}

	handleReload = () => {
		clearLocalTimers()
		location.reload()
	}

	handleFeedback = () => {
		if (typeof window !== 'undefined') {
			Sentry.showReportDialog({
				title: 'Something went badly wrong.',
				subtitle: "We've been notified.",
			})
		}
	}

	render(props, state) {
		if (state.errored) {
			return <div class={style.wrapper}>
				<div class={style.error}>
					<p class={style.message}>Something went badly wrong.</p>
					{/*<FloatingButton onClick={this.handleFeedback}>Send Feedback</FloatingButton>*/}
					<FloatingButton onClick={this.handleReload}>Reset Timer</FloatingButton>
				</div>
			</div>
		}
		return props.children
	}
}