export const Logo = ({...props}) => <svg
	{...props}
	xmlns="http://www.w3.org/2000/svg"
	width="32"
	height="32"
	viewBox="0 0 8.4666665 8.4666669"
	version="1.1"
	id="svg8">
	<path
		style="fill:none;stroke-width:0.529167;stroke-linecap:square;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:markers stroke fill"
		d="M 7.1502673,6.0745954 C 6.5391019,7.0407779 5.4611407,7.6823442 4.2333336,7.6823442 c -1.6340178,0 -3.0026373,-1.1363046 -3.3587725,-2.6618277 M 1.1835022,2.6212026 C 1.762228,1.5286366 2.9108589,0.78432298 4.2333336,0.78432298 c 1.8145538,0 3.3018241,1.40126712 3.4387301,3.18072072" />
	<path
		style="fill-opacity:1;stroke:none;stroke-width:0.529167;stroke-linecap:square;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:markers stroke fill"
		d="M 2.6458333,3.96875 2.0729935,2.9765624 1.5001537,1.9843749 l 1.1456796,0 1.1456794,0 -0.5728398,0.9921875 z"
		transform="matrix(0.13737476,0.78498015,-0.79169633,0.13879847,8.8545813,3.5269755)" />
	<path
		style="fill-opacity:1;stroke:none;stroke-width:0.529167;stroke-linecap:square;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:markers stroke fill"
		d="M 2.6458333,3.96875 2.0729935,2.9765624 1.5001537,1.9843749 l 1.1456796,0 1.1456794,0 -0.5728398,0.9921875 z"
		transform="matrix(0.78373219,0.17373607,-0.17743979,0.77795149,-0.40561869,0.30451946)" />
	<rect
		style="fill-opacity:1;stroke:none;stroke-width:0.47865;stroke-linecap:square;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:markers stroke fill"
		width="0.52916664"
		height="2.3812499"
		x="3.4395835"
		y="2.3812499"
		ry="0.26458332" />
	<rect
		style="fill-opacity:1;stroke:none;stroke-width:0.478651;stroke-linecap:square;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:markers stroke fill"
		width="0.52916664"
		height="2.3812504"
		x="4.4979162"
		y="-6.0854168"
		transform="rotate(90)"
		ry="0.26458332" />
	<rect
		style="fill-opacity:1;stroke:none;stroke-width:0.529167;stroke-linecap:square;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:markers stroke fill"
		width="0.52916664"
		height="0.52916664"
		x="3.4395835"
		y="4.4979162"
		ry="0" />
</svg>


export const Backward = ({...props}) => <svg version="1.1" viewBox="0 0 8.4667 8.4667" xmlns="http://www.w3.org/2000/svg">
	<g transform="rotate(180 6.35 4.3656)">
		<g transform="translate(.37509)">
			<path transform="matrix(.8374 0 0 1.0215 1.6789 .041419)" d="m9.7896 4.2333-5.1594 2.9788v-2.9788-2.9788l2.5797 1.4894z" stroke-linecap="square" stroke-miterlimit="100" stroke-width=".79375" style="paint-order:markers stroke fill"/>
			<rect x="9.8331" y="1.3229" width=".79375" height="6.0854" style="paint-order:markers stroke fill"/>
		</g>
	</g>
</svg>


export const Forward = ({...props}) => <svg version="1.1" viewBox="0 0 8.4667 8.4667" xmlns="http://www.w3.org/2000/svg">
	<g transform="translate(-3.8582)">
		<path transform="matrix(.8374 0 0 1.0215 1.6789 .041419)" d="m9.7896 4.2333-5.1594 2.9788v-2.9788-2.9788l2.5797 1.4894z" stroke-linecap="square" stroke-miterlimit="100" stroke-width=".79375" style="paint-order:markers stroke fill"/>
		<rect x="9.8331" y="1.3229" width=".79375" height="6.0854" style="paint-order:markers stroke fill"/>
	</g>
</svg>


export const DoubleForward = ({...props}) => <svg version="1.1" viewBox="0 0 12.7 8.4667" xmlns="http://www.w3.org/2000/svg">
	<g>
		<path transform="matrix(.8374 0 0 1.0215 .68835 -.028398)" d="m6.813 4.3017-5.1594 2.9788v-2.9788-2.9788l2.5797 1.4894z" stroke-linecap="square" stroke-miterlimit="100" stroke-width=".79375" style="paint-order:markers stroke fill"/>
		<path transform="matrix(.8374 0 0 1.0215 1.6789 .041419)" d="m9.7896 4.2333-5.1594 2.9788v-2.9788-2.9788l2.5797 1.4894z" stroke-linecap="square" stroke-miterlimit="100" stroke-width=".79375" style="paint-order:markers stroke fill"/>
		<rect x="9.8331" y="1.3229" width=".79375" height="6.0854" style="paint-order:markers stroke fill"/>
	</g>
</svg>

export const DoubleBackward = ({...props}) => <svg version="1.1" viewBox="0 0 12.7 8.4667" xmlns="http://www.w3.org/2000/svg">
	<g transform="rotate(180 6.35 4.3656)">
		<path transform="matrix(.8374 0 0 1.0215 .68835 -.028398)" d="m6.813 4.3017-5.1594 2.9788v-2.9788-2.9788l2.5797 1.4894z" stroke-linecap="square" stroke-miterlimit="100" stroke-width=".79375" style="paint-order:markers stroke fill"/>
		<path transform="matrix(.8374 0 0 1.0215 1.6789 .041419)" d="m9.7896 4.2333-5.1594 2.9788v-2.9788-2.9788l2.5797 1.4894z" stroke-linecap="square" stroke-miterlimit="100" stroke-width=".79375" style="paint-order:markers stroke fill"/>
		<rect x="9.8331" y="1.3229" width=".79375" height="6.0854" style="paint-order:markers stroke fill"/>
	</g>
</svg>

export const Pause = ({...props}) => <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
	<path d="M4 4h10v24h-10zM18 4h10v24h-10z"></path>
</svg>

export const Play = ({...props}) => <svg version="1.1" viewBox="0 0 8.4667 8.4667" xmlns="http://www.w3.org/2000/svg">
	<path transform="matrix(.8374 0 0 1.0215 -1.8042 .041419)" d="m9.7896 4.2333-5.1594 2.9788v-2.9788-2.9788l2.5797 1.4894z" stroke-linecap="square" stroke-miterlimit="100" stroke-width=".79375" style="paint-order:markers stroke fill"/>
</svg>

export const Spinner = ({...props}) => <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
	<path d="M32 12h-12l4.485-4.485c-2.267-2.266-5.28-3.515-8.485-3.515s-6.219 1.248-8.485 3.515c-2.266 2.267-3.515 5.28-3.515 8.485s1.248 6.219 3.515 8.485c2.267 2.266 5.28 3.515 8.485 3.515s6.219-1.248 8.485-3.515c0.189-0.189 0.371-0.384 0.546-0.583l3.010 2.634c-2.933 3.349-7.239 5.464-12.041 5.464-8.837 0-16-7.163-16-16s7.163-16 16-16c4.418 0 8.418 1.791 11.313 4.687l4.687-4.687v12z"></path>
</svg>

export const Edit = ({...props}) => <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
	<path d="M27 0c2.761 0 5 2.239 5 5 0 1.126-0.372 2.164-1 3l-2 2-7-7 2-2c0.836-0.628 1.874-1 3-1zM2 23l-2 9 9-2 18.5-18.5-7-7-18.5 18.5zM22.362 11.362l-14 14-1.724-1.724 14-14 1.724 1.724z"></path>
</svg>

export const Cross = () => <svg version="1.1" viewBox="0 0 8.4667 8.4667" xmlns="http://www.w3.org/2000/svg">
	<g>
		<rect transform="scale(-1,1)" x="-4.4979" y=".52917" width=".52917" height="7.4083" style="paint-order:markers stroke fill"/>
		<rect transform="matrix(0,-1,-1,0,0,0)" x="-4.4979" y="-7.9375" width=".52917" height="7.4083" style="paint-order:markers stroke fill"/>
	</g>
</svg>

export const X = () => <svg version="1.1" viewBox="0 0 8.4667 8.4667" xmlns="http://www.w3.org/2000/svg">
	<g>
		<rect transform="matrix(-.70711 -.70711 -.70711 .70711 0 0)" x="-6.2514" y="-3.7042" width=".52917" height="7.4083" style="paint-order:markers stroke fill"/>
		<rect transform="matrix(.70711 -.70711 -.70711 -.70711 0 0)" x="-.26458" y="-9.691" width=".52917" height="7.4083" style="paint-order:markers stroke fill"/>
	</g>
</svg>

export const Share = () => <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
	<path d="M5.080 12.16c-0.539 0.527-1.276 0.852-2.090 0.852-1.651 0-2.99-1.339-2.99-2.99 0-0.008 0-0.015 0-0.023v0.001c0.001-1.656 1.344-2.998 3-2.998 0.808 0 1.542 0.319 2.081 0.839l-0.001-0.001 8.94-4.47c-0.015-0.113-0.024-0.243-0.024-0.375 0-1.657 1.343-3 3-3s3 1.343 3 3c0 1.657-1.343 3-3 3-0.806 0-1.538-0.318-2.077-0.836l0.001 0.001-8.94 4.47c0.014 0.111 0.023 0.24 0.023 0.37s-0.008 0.259-0.024 0.385l0.002-0.015 8.94 4.47c0.539-0.527 1.276-0.852 2.090-0.852 1.651 0 2.99 1.339 2.99 2.99 0 0.008-0 0.015-0 0.023v-0.001c-0.014 1.646-1.352 2.975-3 2.975-1.657 0-3-1.343-3-3 0-0.122 0.007-0.241 0.021-0.359l-0.001 0.014-8.94-4.47z"></path>
</svg>

export const Copy = () => <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
	<path d="M18.984 21v-14.016h-10.969v14.016h10.969zM18.984 5.016q0.797 0 1.406 0.586t0.609 1.383v14.016q0 0.797-0.609 1.406t-1.406 0.609h-10.969q-0.797 0-1.406-0.609t-0.609-1.406v-14.016q0-0.797 0.609-1.383t1.406-0.586h10.969zM15.984 0.984v2.016h-12v14.016h-1.969v-14.016q0-0.797 0.586-1.406t1.383-0.609h12z"></path>
</svg>

export const Tick = () => <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
	<path d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM16.594 7.594l1.406 1.406-8.016 8.016-4.969-5.016 1.406-1.406 3.563 3.563z"></path>
</svg>


export const SlightlySmilingFace = () => <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
	<g id="color">
		<circle cx="36.0001" cy="36" r="22.9999" fill="#FCEA2B"/>
	</g>
	<g id="line">
		<circle cx="36" cy="36" r="23" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
		<path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M45.8149,44.9293 c-2.8995,1.6362-6.2482,2.5699-9.8149,2.5699s-6.9153-0.9336-9.8149-2.5699"/>
		<path d="M30,31c0,1.6568-1.3448,3-3,3c-1.6553,0-3-1.3433-3-3c0-1.6552,1.3447-3,3-3C28.6552,28,30,29.3448,30,31"/>
		<path d="M48,31c0,1.6568-1.3447,3-3,3s-3-1.3433-3-3c0-1.6552,1.3447-3,3-3S48,29.3448,48,31"/>
	</g>
</svg>

export const SmilingEyes = () => <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
  <g id="color">
	<circle cx="36" cy="36" r="23" fill="#FCEA2B"/>
	<path fill="#D22F27" d="M26.4992,27.4384c-1.2653-3.3541-6.441-3.5687-6.1168,1.3178c0.0431,0.6485,0.281,1.2724,0.6414,1.8135 l5.3179,6.4224l0,0l5.2212-6.266c0.5796-0.6964,0.9224-1.5779,0.905-2.4853c-0.0863-4.3523-5.0509-4.0351-6.1274-0.8036"/>
	<path fill="#D22F27" d="M45.8012,27.4384c-1.2547-3.3541-6.3873-3.5687-6.0658,1.3178c0.0428,0.6485,0.2787,1.2724,0.6361,1.8135 l5.2737,6.4224l0,0l5.1777-6.266c0.5747-0.6964,0.9147-1.5779,0.8974-2.4853c-0.0856-4.3523-5.0089-4.0351-6.0763-0.8036"/>
	<path fill="#FFFFFF" d="M48.5859,42.6735c0,5.6296-4.1784,10.1046-12.5541,10.1046c-8.3738,0-12.6069-4.4888-12.6069-10.1047 C23.4249,42.6734,36.4503,45.7045,48.5859,42.6735z"/>
  </g>
  <g id="hair"/>
  <g id="skin"/>
  <g id="skin-shadow"/>
  <g id="line">
	<path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M48.1113,44.5467"/>
	<path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M23.934,44.5467"/>
	<circle cx="36" cy="36" r="23" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
	<path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M48.5859,42.6735c0,5.6296-4.1784,10.1046-12.5541,10.1046c-8.3738,0-12.6069-4.4888-12.6069-10.1047 C23.4249,42.6734,36.4503,45.7045,48.5859,42.6735z"/>
	<path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M26.4992,27.4384c-1.2653-3.3541-6.441-3.5687-6.1168,1.3178c0.0431,0.6485,0.281,1.2724,0.6414,1.8135l5.3179,6.4224l0,0 l5.2212-6.266c0.5796-0.6964,0.9224-1.5779,0.905-2.4853c-0.0863-4.3523-5.0509-4.0351-6.1274-0.8036"/>
	<path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M45.8012,27.4384c-1.2547-3.3541-6.3873-3.5687-6.0658,1.3178c0.0428,0.6485,0.2787,1.2724,0.6361,1.8135l5.2737,6.4224l0,0 l5.1777-6.266c0.5747-0.6964,0.9147-1.5779,0.8974-2.4853c-0.0856-4.3523-5.0089-4.0351-6.0763-0.8036"/>
  </g>
</svg>

export const Sun = () => <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
	<path d="M18 12c0-1.657-0.673-3.158-1.757-4.243s-2.586-1.757-4.243-1.757-3.158 0.673-4.243 1.757-1.757 2.586-1.757 4.243 0.673 3.158 1.757 4.243 2.586 1.757 4.243 1.757 3.158-0.673 4.243-1.757 1.757-2.586 1.757-4.243zM16 12c0 1.105-0.447 2.103-1.172 2.828s-1.723 1.172-2.828 1.172-2.103-0.447-2.828-1.172-1.172-1.723-1.172-2.828 0.447-2.103 1.172-2.828 1.723-1.172 2.828-1.172 2.103 0.447 2.828 1.172 1.172 1.723 1.172 2.828zM11 1v2c0 0.552 0.448 1 1 1s1-0.448 1-1v-2c0-0.552-0.448-1-1-1s-1 0.448-1 1zM11 21v2c0 0.552 0.448 1 1 1s1-0.448 1-1v-2c0-0.552-0.448-1-1-1s-1 0.448-1 1zM3.513 4.927l1.42 1.42c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-1.42-1.42c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414zM17.653 19.067l1.42 1.42c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-1.42-1.42c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414zM1 13h2c0.552 0 1-0.448 1-1s-0.448-1-1-1h-2c-0.552 0-1 0.448-1 1s0.448 1 1 1zM21 13h2c0.552 0 1-0.448 1-1s-0.448-1-1-1h-2c-0.552 0-1 0.448-1 1s0.448 1 1 1zM4.927 20.487l1.42-1.42c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-1.42 1.42c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0zM19.067 6.347l1.42-1.42c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-1.42 1.42c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0z"></path>
</svg>

export const Moon = () => <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
	<path d="M21.996 12.882c0.022-0.233-0.038-0.476-0.188-0.681-0.325-0.446-0.951-0.544-1.397-0.219-0.95 0.693-2.060 1.086-3.188 1.162-1.368 0.092-2.765-0.283-3.95-1.158-1.333-0.985-2.139-2.415-2.367-3.935s0.124-3.124 1.109-4.456c0.142-0.191 0.216-0.435 0.191-0.691-0.053-0.55-0.542-0.952-1.092-0.898-2.258 0.22-4.314 1.18-5.895 2.651-1.736 1.615-2.902 3.847-3.137 6.386-0.254 2.749 0.631 5.343 2.266 7.311s4.022 3.313 6.772 3.567 5.343-0.631 7.311-2.266 3.313-4.022 3.567-6.772zM19.567 14.674c-0.49 1.363-1.335 2.543-2.416 3.441-1.576 1.309-3.648 2.016-5.848 1.813s-4.108-1.278-5.417-2.854-2.016-3.648-1.813-5.848c0.187-2.032 1.117-3.814 2.507-5.106 0.782-0.728 1.71-1.3 2.731-1.672-0.456 1.264-0.577 2.606-0.384 3.899 0.303 2.023 1.38 3.934 3.156 5.247 1.578 1.167 3.448 1.668 5.272 1.545 0.752-0.050 1.496-0.207 2.21-0.465z"></path>
</svg>

export const VolumeMute = ({...props}) => <svg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
<path d="M13 30c-0.26 0-0.516-0.102-0.707-0.293l-7.707-7.707h-3.586c-0.552 0-1-0.448-1-1v-10c0-0.552 0.448-1 1-1h3.586l7.707-7.707c0.286-0.286 0.716-0.372 1.090-0.217s0.617 0.519 0.617 0.924v26c0 0.404-0.244 0.769-0.617 0.924-0.124 0.051-0.254 0.076-0.383 0.076z"></path>
</svg>

export const VolumeLow = ({...props}) => <svg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
<path d="M17.157 23.157c-0.384 0-0.768-0.146-1.061-0.439-0.586-0.586-0.586-1.535 0-2.121 2.534-2.534 2.534-6.658 0-9.192-0.586-0.586-0.586-1.536 0-2.121s1.535-0.586 2.121 0c3.704 3.704 3.704 9.731 0 13.435-0.293 0.293-0.677 0.439-1.061 0.439z"></path>
<path d="M13 30c-0.26 0-0.516-0.102-0.707-0.293l-7.707-7.707h-3.586c-0.552 0-1-0.448-1-1v-10c0-0.552 0.448-1 1-1h3.586l7.707-7.707c0.286-0.286 0.716-0.372 1.090-0.217s0.617 0.519 0.617 0.924v26c0 0.404-0.244 0.769-0.617 0.924-0.124 0.051-0.254 0.076-0.383 0.076z"></path>
</svg>

export const VolumeMedium = ({...props}) => <svg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
<path d="M22.485 25.985c-0.384 0-0.768-0.146-1.061-0.439-0.586-0.586-0.586-1.535 0-2.121 4.094-4.094 4.094-10.755 0-14.849-0.586-0.586-0.586-1.536 0-2.121s1.536-0.586 2.121 0c2.55 2.55 3.954 5.94 3.954 9.546s-1.404 6.996-3.954 9.546c-0.293 0.293-0.677 0.439-1.061 0.439v0zM17.157 23.157c-0.384 0-0.768-0.146-1.061-0.439-0.586-0.586-0.586-1.535 0-2.121 2.534-2.534 2.534-6.658 0-9.192-0.586-0.586-0.586-1.536 0-2.121s1.535-0.586 2.121 0c3.704 3.704 3.704 9.731 0 13.435-0.293 0.293-0.677 0.439-1.061 0.439z"></path>
<path d="M13 30c-0.26 0-0.516-0.102-0.707-0.293l-7.707-7.707h-3.586c-0.552 0-1-0.448-1-1v-10c0-0.552 0.448-1 1-1h3.586l7.707-7.707c0.286-0.286 0.716-0.372 1.090-0.217s0.617 0.519 0.617 0.924v26c0 0.404-0.244 0.769-0.617 0.924-0.124 0.051-0.254 0.076-0.383 0.076z"></path>
</svg>

export const VolumeHigh = ({...props}) => <svg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" width="34" height="32" viewBox="0 0 34 32">
<path d="M27.814 28.814c-0.384 0-0.768-0.146-1.061-0.439-0.586-0.586-0.586-1.535 0-2.121 2.739-2.739 4.247-6.38 4.247-10.253s-1.508-7.514-4.247-10.253c-0.586-0.586-0.586-1.536 0-2.121s1.536-0.586 2.121 0c3.305 3.305 5.126 7.7 5.126 12.374s-1.82 9.069-5.126 12.374c-0.293 0.293-0.677 0.439-1.061 0.439zM22.485 25.985c-0.384 0-0.768-0.146-1.061-0.439-0.586-0.586-0.586-1.535 0-2.121 4.094-4.094 4.094-10.755 0-14.849-0.586-0.586-0.586-1.536 0-2.121s1.536-0.586 2.121 0c2.55 2.55 3.954 5.94 3.954 9.546s-1.404 6.996-3.954 9.546c-0.293 0.293-0.677 0.439-1.061 0.439v0zM17.157 23.157c-0.384 0-0.768-0.146-1.061-0.439-0.586-0.586-0.586-1.535 0-2.121 2.534-2.534 2.534-6.658 0-9.192-0.586-0.586-0.586-1.536 0-2.121s1.535-0.586 2.121 0c3.704 3.704 3.704 9.731 0 13.435-0.293 0.293-0.677 0.439-1.061 0.439z"></path>
<path d="M13 30c-0.26 0-0.516-0.102-0.707-0.293l-7.707-7.707h-3.586c-0.552 0-1-0.448-1-1v-10c0-0.552 0.448-1 1-1h3.586l7.707-7.707c0.286-0.286 0.716-0.372 1.090-0.217s0.617 0.519 0.617 0.924v26c0 0.404-0.244 0.769-0.617 0.924-0.124 0.051-0.254 0.076-0.383 0.076z"></path>
</svg>