import { h, Component } from 'preact'
import style from './style'
import * as Icons from '../../assets/icons/svg-icons'

export const FloatingButton = ({children, icon, primary, ...props}) => {
	const Icon = icon ? Icons[icon] : null
	return <button class={`
			${style.floatingButton}
			${primary ? style.floatingButtonPrimary : null}
		`} {...props}>
		{icon && <Icon />}
		{children}
	</button>
}

export const TooltipButton = ({children, icon, ...props}) => {
	const Icon = icon ? Icons[icon] : null
	return <button class={`
			${style.tooltipButton}
		`} {...props}>
		<Icon />
		{children}
	</button>
}

export const IconToggle = ({isActive, iconOn, iconOff, ...props}) => {
	const IconOn = iconOn ? Icons[iconOn] : null
	const IconOff = iconOff ? Icons[iconOff] : null

	return <div class={style.iconToggle} {...props}>
		{isActive 
			? <IconOn />
			: <IconOff />
		}
	</div>
}

export const PlayerControlButton = ({text, disabled, onClick, children, icon, grow = false}) => {
	const Icon = Icons[icon]

	const classes = {
		playerControlButton: true,
		grow,
	}

	let classList = ''
	for (let key in classes) {
		if (classes[key]) {
			classList += `${style[key]} `
		}
	}

	return (
		<button
			class={classList}
			onClick={onClick}
			disabled={disabled}>
			{icon && <Icon />}
			{children}
			{text &&<span class={style.text}>{text}</span>}
		</button>
	)
}