import { useEffect, useRef } from 'preact/hooks'
import * as workerTimers from 'worker-timers'

export const parseSets = sets => {
	const initSets = []

	sets.forEach((s, i) => {
		const set = {
			name: s.name,
			duration: getSetTotalDuration(s),
			id: s.id,
			idx: i,
			roundCount: s.roundCount,
			skipped: s.skipped || false,
			segments: []
		}
		if (s.warmUp) {
			set.segments.push({ name: 'Warm up', duration: s.warmUp, status: 'warmUp'})
		}
		for (let i = 1; i <= s.roundCount; i++) {
			set.segments.push({ name: 'Go', duration: s.roundLength, status: 'round'})
			// add rest after each round except last
			if (s.rest && i !== s.roundCount) {
				set.segments.push({ name: 'Rest', duration: s.rest, status: 'rest'})
			}
		}

		initSets.push(set)
	})

	return initSets
}

export const debounce = (func, wait) => {
	let timeout

	const later = () => {
		clearTimeout(timeout)
		func()
	}

	clearTimeout(timeout)
	timeout = setTimeout(later, wait)
}

// round up to nearest 1000 to keep ui looking synced with countdowns
export const ceilSec = ms => {
	return Math.ceil(ms/1000)*1000
}

export const msToSeconds = ms => ms / 1000

export const msToTime = ms => {
	// const hrs = Math.floor(((ms / 1000) / 60) / 60)
	// ms = ms - ((hrs * 60) * 60) * 1000

	const mins = Math.floor((ms / 1000) / 60)
	ms = ms - ((mins * 60) * 1000)

	const secs = Math.floor(ms / 1000)

	return `${mins < 10 ? 0 : ''}${mins}:${secs < 10 ? 0 : ''}${secs}`
}

export const msToTimeObj = ms => {
	const obj = {}

	// obj.days = Math.floor((((ms / 1000) / 60) / 60) / 24)
	// ms = ms - (((obj.days * 24) * 60) * 60) * 1000

	// obj.hrs = Math.floor(((ms / 1000) / 60) / 60)
	// ms = ms - ((obj.hrs * 60) * 60) * 1000

	obj.mins = Math.floor((ms / 1000) / 60)
	ms = ms - ((obj.mins * 60) * 1000)

	obj.secs = Math.floor(ms / 1000)



	return obj
}

export const timeObjToMs = obj => {
	let ms = 0

	ms += obj.secs * 1000
	ms += obj.mins * (1000 * 60)
	// ms += obj.hrs * ((1000 * 60) * 60)
	// ms += obj.days * (((1000 * 60) * 60) * 24)

	return ms
}

export const addLeadingZero = num => {
	if (num < 10) {
		return `0${num}`
	} 
	return num
}

export const useInterval = (callback, delay) => {
	const savedCallback = useRef()

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current()
			// requestAnimationFrame(savedCallback.current)
		}
		if (delay !== null) {
			const id = workerTimers.setInterval(tick, delay)
			return () => workerTimers.clearInterval(id)
		}
	}, [delay])
}

export const generateId = (num = 8) => {
	return Math.random().toString(num).substr(2, 9)
}

export const getSetTotalDuration = set => {
	let totalMs = 0
	totalMs += set.warmUp
	totalMs += (set.roundLength * set.roundCount)
	totalMs += (set.rest * (set.roundCount - 1))

	return totalMs
}

export const getLocalDarkmode = () => localStorage.TimerFitDarkmode && JSON.parse(localStorage.TimerFitDarkmode)

export const setLocalDarkmode = isActive => localStorage.setItem('TimerFitDarkmode', isActive)

export const getLocalTimers = () => Object.entries(localStorage).filter(l => l[0].indexOf('TimerFit') > -1).map(t => JSON.parse(t[1])).sort((a, b) => a.timestamp < b.timestamp).filter(k => k.id)

export const setLocalTimer = (id, data) => {
	data = {
		...data,
		timestamp: Date.now()
	}

	return localStorage.setItem(`TimerFit${id}`, JSON.stringify(data))
}

export const deleteLocalTimer = id => localStorage.removeItem(`TimerFit${id}`)

export const clearLocalTimers = () => {
	const timers = getLocalTimers()

	timers.forEach((t) => {
		localStorage.removeItem(`TimerFit${t.id}`)
	})
}

export const getLocalTimer = id => JSON.parse(localStorage.getItem(`TimerFit${id}`))

export const parseShareParams = ({loop, params, ...rest}) => {
	const timerObj = {
		name: 'Timer 1',
		id: 1,
		loop: loop === 'true',
		sets: []
	}

	for (const k in rest) {
		const values = rest[k].split(',')
		// always read plus as space
		values[0] = values[0].split('+').join(' ')

		timerObj.sets.push({
			name: values[0],
			roundLength: values[1] * 1000,
			roundCount: parseInt(values[2]) > 1 ? parseInt(values[2]) : 1,
			rest: values[3] * 1000,
			warmUp: values[4] * 1000,
			id: generateId()
		})
	}

	return timerObj
}

export const timerToShareURL = id => {
	if (typeof window === 'undefined') { return }

	const timerObj = getLocalTimer(id)
	// TODO: load share url on click
	if (!timerObj) { return }
	const loopStr = timerObj.loop ? `&loop=${timerObj.loop}` : ''
	let url = `${window.location.origin}/play?`

	timerObj.sets.forEach((s, i) => {
		url += `${i > 0 ? '&' : ''}${i}=${s.name},${s.roundLength / 1000},${s.roundCount},${s.rest / 1000},${s.warmUp / 1000}`
	})

	url += loopStr

	// replace spaces with + symbols instead
	return encodeURI(url).replace(/%20/g, '+')
}

export const copyToClipboard = (text) => {
	if (typeof window === 'undefined') { return }
	if (window.clipboardData && window.clipboardData.setData) {
		// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
		return clipboardData.setData('Text', text)
	}
	else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
		var textarea = document.createElement('textarea')
		textarea.textContent = text
		textarea.style.position = 'fixed'  // Prevent scrolling to bottom of page in Microsoft Edge.
		document.body.appendChild(textarea)
		textarea.select()
		try {
			return document.execCommand('copy')  // Security exception may be thrown by some browsers.
		}
		catch (ex) {
			console.warn('Copy to clipboard failed.', ex)
			return false
		}
		finally {
			document.body.removeChild(textarea)
		}
	}
}

export const isElementInViewport = (el) => {
	if (typeof window === 'undefined') { return }
	var rect = el.getBoundingClientRect()

	return rect.bottom > 0 &&
		rect.right > 0 &&
		rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
		rect.top < (window.innerHeight || document.documentElement.clientHeight)
}

export const isVisible = elem => !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length ) // source (2018-03-11): https://github.com/jquery/jquery/blob/master/src/css/hiddenVisibleSelectors.js

export const useClickOutside = (callback, el) => {
	useEffect(() => {
		if (el) {
			const outsideClickListener = event => {
				if (!el.contains(event.target) && isElementInViewport(el)) { // or use: event.target.closest(selector) === null
					callback()
					removeClickListener()
				}
			}

			const removeClickListener = () => {
				document.removeEventListener('click', outsideClickListener)
			}

			document.addEventListener('click', outsideClickListener)
		}
	}, [callback, el])
}

export const initEnvironment = () => {
	if (typeof window === 'undefined') { return }
	// Object Entries Polyfill
	if (!Object.entries) {
		Object.entries = function( obj ){
			var ownProps = Object.keys( obj ),
				i = ownProps.length,
				resArray = new Array(i) // preallocate the Array
			while (i--)
				resArray[i] = [ownProps[i], obj[ownProps[i]]]

			return resArray
		}
	}

	// Number.isInteger Polyfill
	Number.isInteger = Number.isInteger || function(value) {
		return typeof value === 'number' &&
		isFinite(value) &&
		Math.floor(value) === value
	}

	// forEach Polyfill
	if (window.NodeList && !NodeList.prototype.forEach) {
		NodeList.prototype.forEach = Array.prototype.forEach;
	}
}