import { h, Component } from 'preact';
import { Router, getCurrentUrl } from 'preact-router';
import {TimerAPI} from './api'
import {deleteLocalTimer, generateId, setLocalTimer, getLocalTimer, getLocalTimers, setLocalDarkmode, getLocalDarkmode, debounce, initEnvironment} from '../helpers/functions'
import {setsDefault, timerDefault} from '../helpers/constants'
import Redirect from './redirect'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// Code-splitting is automated for routes
import Edit from '../routes/edit';
import Player from '../routes/player';

initEnvironment()

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn: "https://c7b143f471ee4759825c213140801f3c@o522652.ingest.sentry.io/5634378",
		integrations: [new Integrations.BrowserTracing()],

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,
		autoSessionTracking: false,
		environment: process.env.NODE_ENV
	});
}

export default class App extends Component {
	constructor() {
		super();
		this.state = {
			darkModeActive: true,
			toggleDarkMode: this.toggleDarkMode,
			newTimer: this.newTimer,
			setTimer: this.setTimer,
			deleteTimer: this.deleteTimer,
			updateTimer: this.updateTimer,
			addSet: this.addSet,
			updateSet: this.updateSet,
			removeSet: this.removeSet,
			toggleLoop: this.toggleLoop,
			totalDuration: this.totalDuration,
			avgSegmentDuration: this.avgSegmentDuration,
			updateVolume: this.updateVolume,
			...timerDefault,
		}
	}

	newTimer = data => {
		const localTimerCount = getLocalTimers().length
		// TODO: Check id's to make sure it doesn't exist
		data = {
			name: 'Timer ' + (localTimerCount + 1),
			sets: setsDefault,
			...data,
			id: data.id || localTimerCount + 1,
		}

		this.setState(data)
		setLocalTimer(data.id, data)
	}


	setTimer = id => {
		let data = getLocalTimer(id)

		if (data === null || typeof data === 'undefined') {
			data = timerDefault
		}

		data.sets.forEach((s, i) => {
			if (s.roundCount < 1) {
				data.sets[i] = 1
			}
		})

		setLocalTimer(id, data)
		this.setState(data)
	}

	deleteTimer = id => {
		deleteLocalTimer(id)
		this.setTimer(id === this.state.id ? this.setTimer(getLocalTimers()[0].id) : this.setState({}))
	}

	addSet = set => {
		const setID = (new Date().getTime()).toString(36)
		const newArr = [...this.state.sets]
		newArr.push({...set, id: setID})
		this.setState({sets: newArr}, () => {

			const {id, sets, name} = this.state
			setLocalTimer(id, {id, sets, name})
		})
	}

	updateSet = (setID, values) => {
		const newArr = this.state.sets
		const idx = newArr.findIndex(s => s.id === setID)
		newArr[idx] = Object.assign(newArr[idx], values)

		this.setState({sets: newArr}, () => {
			const {id, sets, name} = this.state
			setLocalTimer(id, {id, sets, name})
		})
	}

	toggleLoop = e => {
		const loop = !this.state.loop
		this.setState({loop: loop})
		setLocalTimer(this.state.id, {...this.state, loop: loop})
	}

	updateTimer = (values) => {
		const data = {
			...this.state,
			...values
		}

		this.setState(data)
		setLocalTimer(this.state.id, data)
	}

	removeSet = id => {
		const newArr = [...this.state.sets]
		newArr.splice(newArr.findIndex(s => s.id === id), 1)
		this.setState({sets: newArr}, () => {
			setLocalTimer(this.state.id, this.state)
		})
	}

	totalDuration = () => {
		let ms = 0;
		this.state.sets.forEach(s => {
			ms += s.warmUp;
			ms += s.rest * (s.roundCount - 1);
			ms += s.roundLength * s.roundCount;
		})
		return ms;
	}

	avgSegmentDuration = () => {
		let ms = 0;
		let counter = 0;
		this.state.sets.forEach(s => {
			if (s.warmUp) {
				ms += s.warmUp
				counter++
			}
			if (s.rest) {
				ms += s.rest
				counter++
			}
			ms += s.roundLength
			counter++
		})
		ms = ms / counter;
		return ms;
	}

	toggleDarkMode = () => {
		const isActive = !this.state.darkModeActive
		this.setState({
			darkModeActive: isActive
		})
		setLocalDarkmode(isActive)
	}

	setInnerHeightCSSVar = () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}

	updateVolume = amount => {
		this.setState({
			volume: parseInt(amount) / 100
		}, () => {
			setLocalTimer(this.state.id, this.state)
		})
	}

	componentDidMount() {
		if (getLocalTimers()[0]) {
			this.setTimer(getLocalTimers()[0].id)
		} else {
			setLocalTimer(this.state.id, this.state)
		}

		if (getLocalDarkmode() !== undefined) {
			this.setState({
				darkModeActive: getLocalDarkmode()
			})
		}

		this.setInnerHeightCSSVar()

		window.addEventListener('resize', () => 
			debounce(() => {
				this.setInnerHeightCSSVar()
			}, 250)
		)
	}
	
	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.currentUrl = e.url;
	};

	render(props) {
		return (
			<div id="app" class={`app${this.state.darkModeActive ? ' darkMode' : ''}`}>
				<TimerAPI.Provider value={this.state}>
						<Router onChange={this.handleRoute}>
							<Edit path="/" />
							<Player path="/play/:params?" />
						</Router>
				</TimerAPI.Provider>
			</div>
		);
	}
}
